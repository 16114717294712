import GetDataMock from "./mockup/getData.json";
import dbEvent from "./mockup/dbEvent.json";
import getPost from "./mockup/getPost.json";
import getNoti from "./mockup/getNoti.json";
import { craApi, craPublicApi } from "./urlConfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";
var XMLParser = require("react-xml-parser");


// const mockup = true

export function getConfPage(param) {
  //console.log("process.env.REACT_APP_MOCKUPMODE", process.env.REACT_APP_MOCKUPMODE)
  //console.log(param)
  if (process.env.REACT_APP_MOCKUPMODE == "true") {
    const res = axios
      .get("./mockup/getConfPage.json")
      .then((ress) => {
        //console.log("mock ress", ress)
        return ress;
      })
      .catch((error) => {
        //console.log(error)
        return error;
      });
    //console.log("res", res)
    return res;
  } else {
    const res = axios
      .post(process.env.REACT_APP_FOCUSAPI + "/sensor_data", param)
      .then((ress) => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch((error) => {
        //console.log(error)
        return error;
      });
    return res;
  }
  //return res
}

export function logIn(param) {
  if (!true) {
    const res = axios
      .get("./mockup/logIn.json")
      .then((ress) => {
        //console.log("mock ress", ress)
        return ress;
      })
      .catch((error) => {
        //console.log(error)
        return error;
      });
    return res;
  } else {
    const res = axios
      .post(process.env.REACT_APP_APIAUTHURL + "/login", param)
      .then((ress) => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch((error) => {
        //console.log(error)
        return error;
      });
    return res;
  }
  //return res
}

export function getUserData(param) {
  console.log(process.env.REACT_APP_APIAUTHURL + "/db/user/", param.user_id);
  if (!true) {
    const res = axios
      .get("./mockup/getProfile.json")
      .then((ress) => {
        //console.log("mock ress", ress)
        return ress;
      })
      .catch((error) => {
        //console.log(error)
        return error;
      });
    return res;
  } else {
    const res = axios
      .get(process.env.REACT_APP_APIAUTHURL + "/db/user/" + param.user_id)
      .then((ress) => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch((error) => {
        //console.log(error)
        return error;
      });
    return res;
  }
  //return res
}

export function getProfile(token) {
  if (!true) {
    const res = axios
      .get("./mockup/getProfile.json")
      .then((ress) => {
        //console.log("mock ress", ress)
        return ress;
      })
      .catch((error) => {
        //console.log(error)
        return error;
      });
    return res;
  } else {
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const res = axios
      .get(process.env.REACT_APP_APIAUTHURL + "/user/role", config)
      .then((ress) => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch((error) => {
        //console.log(error)
        return error;
      });
    return res;
  }
  //return res
}

export function getData(id) {
  let result = {};
  if (!true) {
    console.log("Mock Env");
    return GetDataMock;
  } else {
    console.log(
      `https://cra.mstws.cloud/pmsapi/service/parking_trans?ticket_id=${id}`
    );
    const res = axios
      .get(
        `https://cra.mstws.cloud/pmsapi/service/parking_trans?ticket_id=${id}`
      )
      .then((ress) => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch((error) => {
        //console.log(error)
        return error;
      });
    return res;
  }
}

export function getStatusType(token) {
  const header = {
    Authorization: "Bearer " + token,
  };
  let result = {}
  if (!true) {
    console.log('Mock Env')
    return dbEvent
  } else {

    const res = axios.get(process.env.REACT_APP_APIAUTHURL + "/history/type", { headers: header })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }
}


export function getStatusBookingType(token) {
  const header = {
    Authorization: "Bearer " + token,
  };
  let result = {}
  if (!true) {
    console.log('Mock Env')
    return dbEvent
  } else {

    const res = axios.get(process.env.REACT_APP_APIAUTHURL + "/booking/type", { headers: header })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }
}


export function getEventData(token, params) {
  const header = {
    Authorization: "Bearer " + token,
  };
  let result = {}
  if (!true) {
    console.log('Mock Env')
    return dbEvent
  } else {

    const res = axios.get(process.env.REACT_APP_APIAUTHURL + "/history", { headers: header, ...params })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }
}

export function getCarEventData(token, params) {
  const header = {
    Authorization: "Bearer " + token,
  };
  let result = {}
  if (!true) {
    console.log('Mock Env')
    return dbEvent
  } else {

    const res = axios.get(process.env.REACT_APP_APIAUTHURL + "/event/car", { headers: header, ...params })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }
}

export function getBookingData(token, params) {
  const header = {
    Authorization: "Bearer " + token,
  };
  let result = {}
  if (!true) {
    console.log('Mock Env')
    return dbEvent
  } else {

    const res = axios.get(process.env.REACT_APP_APIAUTHURL + "/booking", { headers: header, ...params })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }
}

export function CreatBooking(token, body) {
  const header = {
    Authorization: "Bearer " + token,
  };
  let result = {}
  if (!true) {
    console.log('Mock Env')
    return dbEvent
  } else {

    const res = axios.post(process.env.REACT_APP_APIAUTHURL + "/booking", body, { headers: header })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }
}
export function DeleteBooking(token, booking_id) {
  const header = {
    Authorization: "Bearer " + token,
  };
  let result = {}
  if (!true) {
    console.log('Mock Env')
    return dbEvent
  } else {

    const res = axios.delete(process.env.REACT_APP_APIAUTHURL + "/booking/" + booking_id, { headers: header })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }
}

export function getCarTagData() {
  let result = {};
  if (!true) {
    console.log("Mock Env");
    return dbEvent;
  } else {
    const res = axios
      .get(process.env.REACT_APP_APIAUTHURL + "/db/car")
      .then((ress) => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch((error) => {
        //console.log(error)
        return error;
      });
    return res;
  }
}


export function getPostData(token, params) {

  if (!true) {
    console.log('Mock Env')
    return getPost
  } else {
    const header = {
      Authorization: "Bearer " + token,
    };

    const res = axios.get(process.env.REACT_APP_APIAUTHURL + "/post", { headers: header, ...params })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }
}

export function UpdatePostData(token, post_id, post_type, insert_type, body) {

  if (!true) {
    console.log('Mock Env')
    return getPost
  } else {
    const header = {
      Authorization: "Bearer " + token,
    };

    const res = axios.post(process.env.REACT_APP_APIAUTHURL + `/post/${post_id}/${post_type}/${insert_type}`, body, { headers: header })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }
}

export function getAvaliableSlotData(token, params) {

  if (!true) {
    console.log('Mock Env')
    return getPost
  } else {
    const header = {
      Authorization: "Bearer " + token,
    };

    const res = axios.get(process.env.REACT_APP_APIAUTHURL + "/booking/available_slot", { headers: header, ...params })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }
}

export function getDepartmentData(token) {

  if (!true) {
    console.log('Mock Env')
    return getPost
  } else {
    const header = {
      Authorization: "Bearer " + token,
    };

    const res = axios.get(process.env.REACT_APP_APIAUTHURL + "/booking/department", { headers: header })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }
}
export function getParkingSlotStatDataUnique(params) {
  if (!true) {
    console.log("Mock Env");
    return getPost;
  } else {
    const res = axios
      .get(process.env.REACT_APP_APIAUTHURL + "/parking_slot/stats/unique", params)
      .then((ress) => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch((error) => {
        //console.log(error)
        return error;
      });
    return res;
  }
}

export function getStatParkingSlot(param) {
  if (!true) {
    console.log("Mock Env");
    return getPost;
  } else {
    const res = axios
      .get(process.env.REACT_APP_APIAUTHURL + "/parking_slot/building/stats", param)
      .then((ress) => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch((error) => {
        //console.log(error)
        return error;
      });
    return res;
  }
}

export const getOneBuilding = async (buildingID) => {
  const { data } = await craApi.get(`/db/building/${buildingID}`);
  return data;
};

export const getAllFloorsByBuildingID = async (buildingID) => {
  const { data } = await craApi.get(`/building/${buildingID}/floor`);
  return data;
};

export const getAllBuildings = async () => {
  const { data } = await craApi.get(`/db/building`);
  return data.items;
};

export const getAllPlaces = async () => {
  const { data } = await craApi.get(`/place`);
  return data;
};

export const publicGetAllPlaces = async () => {
  const { data } = await craPublicApi.get(`/place`);
  return data;
};

export const getPlaceNoti = async () => {
  const { data } = await craApi.get(`/place/notification`);
  return data;
};

export const getAllGateByBuildId = async (build_id) => {
  const { data } = await craApi.get(`/building/${build_id}/gate`);
  return data;
};

export const getGateData = async (build_id, gate_id) => {
  const { data } = await craApi.get(`/building/${build_id}/gate/${gate_id}`);
  return data;
};

export const getGateLastEvent = async (sn, inst_id, action) => {
  let data
  try {
    await craApi.get(`/event/last_record?serial_number=${sn}&instance_id=${inst_id}&action=${action}`).then((res) => {
      console.log("api res", res)
      data = res.data
    })
  } catch (err) {
    let data = undefined
  }
  return data;
};

export const getGateLastStatus = async (sn, inst_id) => {
  let data
  try {
    await craApi.get(`/gate_status/last_record?serial_number=${sn}&instance_id=${inst_id}`).then((res) => {
      console.log("api res", res)
      data = res.data
    })
  } catch (err) {
    let data = undefined
  }
  return data;
};

export const commandGateDevice = async (token, bid, gateid, deviceid, cmdid) => {
  console.log("bid, gateid, deviceid, cmdid", bid, gateid, deviceid, cmdid)
  const header = {
    Authorization: "Bearer " + token,
  };
  let data
  try {
    await craApi.put(`/building/${bid}/gate/${gateid}/device/${deviceid}/action?cmd_id=${cmdid}`, header).then((res) => {
      console.log("api res", res)
      data = res.data
    })
  } catch (err) {
    let data = undefined
  }
  return data;
};


export const getAllServiceCar = async () => {
  const { data } = await craApi.get(`/db/service-car`);
  return data.items;
};

export const getServiceCarGps = async (token) => {
  const header = {
    Authorization: "Bearer " + token,
  };
  if (!true) {
    console.log('Mock Env')
    return getNoti
  } else {
    const res = axios.get(process.env.REACT_APP_APIAUTHURL + "/service_car/gps", { headers: header })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }

};

export const publicGetServiceCarGps = async () => {
  if (!true) {
    console.log('Mock Env')
    return getNoti
  } else {
    const res = axios.get(process.env.REACT_APP_APIPUBLIC + "/service_car/gps")
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }

};

export const getServiceCarStatus = async (token, param, publicCall) => {
  const header = {
    Authorization: "Bearer " + token,
  };
  if (!true) {
    console.log('Mock Env')
    return getNoti
  } else {
    let url = process.env.REACT_APP_APIAUTHURL
    if (publicCall) {
      url = process.env.REACT_APP_APIPUBLIC
    }
    const res = axios.get(url + "/service_car/status", { headers: header, ...param })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }

};

export const getScBuildingStats = async (token, param) => {
  const header = {
    Authorization: "Bearer " + token,
  };
  if (!true) {
    console.log('Mock Env')
    return getNoti
  } else {
    const res = axios.get(process.env.REACT_APP_APIAUTHURL + "/service_car/park/building", { headers: header, ...param })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }

};

export const getScMileStats = async (token, param) => {
  const header = {
    Authorization: "Bearer " + token,
  };
  if (!true) {
    console.log('Mock Env')
    return getNoti
  } else {
    const res = axios.get(process.env.REACT_APP_APIAUTHURL + "/service_car/mileage/stats", { headers: header, ...param })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }

};

export const getScParkStats = async (token, param) => {
  const header = {
    Authorization: "Bearer " + token,
  };
  if (!true) {
    console.log('Mock Env')
    return getNoti
  } else {
    const res = axios.get(process.env.REACT_APP_APIAUTHURL + "/service_car/park/stats", { headers: header, ...param })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }

};

export const getScSpeedStat = async (token, param) => {
  const header = {
    Authorization: "Bearer " + token,
  };
  if (!true) {
    console.log('Mock Env')
    return getNoti
  } else {
    const res = axios.get(process.env.REACT_APP_APIAUTHURL + "/service_car/speed/stats", { headers: header, ...param })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }

};

export const getAllFloors = async () => {
  const { data } = await craApi.get(`/db/floor`);
  return data.items;
};

export const getNotifications = async (token, params) => {
  const header = {
    Authorization: "Bearer " + token,
  };
  if (!true) {
    console.log('Mock Env')
    return getNoti
  } else {
    const res = axios.get(process.env.REACT_APP_APIAUTHURL + "/notification", { headers: header, ...params })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }
}

export const updateNotification = async (token, noti_id, event_type, body) => {
  const header = {
    Authorization: "Bearer " + token,
  };
  if (!true) {
    console.log('Mock Env')
    return getNoti
  } else {
    const res = axios.post(process.env.REACT_APP_APIAUTHURL + `/notification/${noti_id}/${event_type}`, body, { headers: header })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }
}

export const getCardInfo = async (card_id) => {

  if (!true) {
    console.log('Mock Env')
    return getNoti
  } else {
    const res = axios.get(process.env.REACT_APP_APIAUTHURL + `/parking_fee/card/${card_id}`)
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }
}


export const updateCardInfo = async (token, body) => {
  const header = {
    Authorization: "Bearer " + token,
  };
  if (!true) {
    console.log('Mock Env')
    return getNoti
  } else {
    const res = axios.post(process.env.REACT_APP_APIAUTHURL + `/parking_fee`, body, { headers: header })
      .then(ress => {
        //console.log("api ress", ress)
        return ress;
      })
      .catch(error => {
        //console.log(error)
        return error;
      })
    return res

  }
} 