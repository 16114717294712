import { SystemFooter } from "../minesstreamer/systemFooter";
import SubNavBar from "./SubNavBar";
import { Outlet } from "react-router-dom";

function StatisticsLayout() {
  return (
    <main className="z-[100] min-w-full min-h-full overflow-y-auto overflow-x-hidden px-20 py-10 pt-[113px] h-full">
      {/* <div className="container w-full h-full flex justify-center mx-auto items-center py-10"> */}
      <div className="lg:max-w-[1280px] 2xl:max-w-[1440px] w-full h-full flex mx-auto">
        <div className="relative w-full h-full space-y-6">
          {/* sub navigation */}
          <header className="w-full flex justify-start h-10">
            <SubNavBar />
          </header>

          {/* main content */}
          <main className="w-full h-full overflow-y-hidden overflow-x-hidden">
            <Outlet />
          </main>
        </div>
      </div>
      <SystemFooter/>
    </main>
  );
}

export default StatisticsLayout;
