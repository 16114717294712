import { NavLink } from "react-router-dom";

function SubNavBar() {
  return (
    <div className="w-full grid grid-flow-col auto-cols-max h-full border-b text-sm divide-x">
      <NavLink
        to="/statistics/parkingFee"
        className="relative group h-full w-full"
        caseSensitive
        end
      >
        {({ isActive }) => (
          <div
            className={`outline-none ring-0 px-4 h-full items-center justify-center flex ${
              isActive ? "font-bold text-primary" : "font-medium"
            }`}
          >
            <span>Parking Fee</span>
            <span
              className={`${
                isActive ? "block" : "hidden"
              } absolute left-0 bottom-0 w-full h-[2px] bg-primary -z-10`}
            ></span>
          </div>
        )}
      </NavLink>
      <NavLink
        to="/statistics/parkingLots"
        className="relative group h-full w-full"
        caseSensitive
        end
      >
        {({ isActive }) => (
          <div
            className={`outline-none ring-0 px-4 h-full items-center justify-center flex ${
              isActive ? "font-bold text-primary" : "font-medium"
            }`}
          >
            <span>Parking Lots</span>
            <span
              className={`${
                isActive ? "block" : "hidden"
              } absolute left-0 bottom-0 w-full h-[2px] bg-primary -z-10`}
            ></span>
          </div>
        )}
      </NavLink>
      <NavLink
        to="/statistics/parkingEvents"
        className="relative group h-full w-full"
        caseSensitive
        end
      >
        {({ isActive }) => (
          <div
            className={`outline-none ring-0 px-4 h-full items-center justify-center flex ${
              isActive ? "font-bold text-primary" : "font-medium"
            }`}
          >
            <span>Parking Events</span>
            <span
              className={`${
                isActive ? "block" : "hidden"
              } absolute left-0 bottom-0 w-full h-[2px] bg-primary -z-10`}
            ></span>
          </div>
        )}
      </NavLink>
      <NavLink
        to="/statistics/dynamicDashboard"
        className="relative group h-full w-full"
        caseSensitive
        end
      >
        {({ isActive }) => (
          <div
            className={`outline-none ring-0 px-4 h-full items-center justify-center flex ${
              isActive ? "font-bold text-primary" : "font-medium"
            }`}
          >
            <span>Dynamic Dashboard</span>
            <span
              className={`${
                isActive ? "block" : "hidden"
              } absolute left-0 bottom-0 w-full h-[2px] bg-primary -z-10`}
            ></span>
          </div>
        )}
      </NavLink>
    </div>
  );
}

export default SubNavBar;
