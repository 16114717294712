import React, { Fragment, useEffect, useState, useMemo, useRef } from "react";
import 'animate.css';

export const SystemFooter = (props) => {
  const { textColor } = props
  useEffect(() => {


  }, [])

  return (
    <div className="m-5 mt-10 text-center text-[12px] font-[Arial]" style={{
      color: textColor? textColor : "auto" 
    }}>
      Powered by <a target="_blank" className="font-bold text-[#fff] bg-[#003366] p-1 rounded" href="http://www.minesstream.com">Modixtream</a> IOC Platform <br />
      <span className="text-[10px]">Copyright © 2023 <a target="_blank" className="font-bold" style={{
        color: textColor? textColor : "#003366" 
      }} href="http://www.minesstream.com">Mines Stream Technology Co.,ltd</a> All rights reserved.</span>
    </div>
  );
};
