import { authAction } from "./auth.reducer";
import jwt_decode from "jwt-decode";

import { logIn, getProfile, getUserData } from "../../minesstreamer/connecter/apiCaller";
//LOGIN
const Key = {
    TOKEN: "Token",
    UserId: "UserId",
    UserData: "UserData",
    UserProfile: "UserProfile",
    CurrentTime: "CurrentTime"
};
export const loginLoading = () => {
    return {
        type: authAction.LOGIN_LOADING,
    };
};

export const loginSuccess = (data, data_profile, cb) => {
    // notification["success"]({
    //     message: "ลงชื่อเข้าใช้สำเร็จ",
    // });

    let now = new Date().getTime()

    var decodedHeader = jwt_decode(data.data.access_token);
    localStorage.setItem(Key.UserId, decodedHeader.data.user_id);
    localStorage.setItem(Key.TOKEN, data.data.access_token + "_ts" + decodedHeader.exp);
    localStorage.setItem(Key.UserData, JSON.stringify(decodedHeader.data));
    data_profile.software_permission.forEach(function (permission) {
        permission.enable = true
    })
    localStorage.setItem(Key.UserProfile, JSON.stringify(data_profile));
    if (cb) {
        cb(data);
    }
    return {
        type: authAction.LOGIN_SUCCESS,
        data,
    };
};

export const loginFailed = (error, cb) => {
    if (error) {
        // notification["error"]({
        //     message: "เกิดข้อผิดพลาด",
        //     description: error?.statusText,
        // });
        cb(error)
    }
    return {
        type: authAction.LOGIN_FAILED,
        error,
    };
};
export const login = (data, cb) => {
    return (dispatch) => {
        dispatch(loginLoading());
        logIn(data)
            .then((response) => {
                // dispatch(loginSuccess(response, cb));
                var decoded = jwt_decode(response.data.access_token);
                getUserData(decoded.data)
                    .then((response_user) => {
                        getProfile(response.data.access_token)
                            .then((response_allprofile) => {
                                //console.log("... then", response_allprofile)
                                let thisprofile = response_allprofile.data
                                // response_sub.data.forEach(function (profile) {
                                //     if (profile.role_id == response.data.user.role_profile) {
                                //         thisprofile = profile
                                //     }
                                // })
                                dispatch(loginSuccess(response, thisprofile, cb));
                            })
                            .catch((error_sub) => {
                                //console.log("... err")
                                dispatch(loginFailed(error_sub?.response, cb));
                            });
                    })
                    .catch((error_user) => {
                        //console.log("... err")
                        dispatch(loginFailed(error_user?.response, cb));
                    });
                //};
            })
            .catch((error) => {
                //console.log("... err")
                dispatch(loginFailed(error?.response, cb));
            });
    };
};
//VALIDATE AUTH
export const validateAuthLoading = () => {
    return {
        type: authAction.VALIDATE_AUTH_LOADING,
    };
};

export const validateAuthSuccess = (token, cb, userdata, userProfile, currentTime) => {
    //console.log("success")
    if (cb) {
        cb(token, userdata, userProfile, currentTime)
    }
    return {
        type: authAction.VALIDATE_AUTH_SUCCESS,
    };
};

export const validateAuthFailed = (cb) => {
    //console.log("fail")
    if (cb != undefined) {
        cb()
    }
    return {
        type: authAction.VALIDATE_AUTH_FAILED,
    };
};

export const validateAuth = (cb) => {
    const token = localStorage.getItem(Key.TOKEN);
    let userdata = localStorage.getItem(Key.UserData)
    if (userdata != null) {
        userdata = JSON.parse(userdata)
        if (userdata.area == "0" || userdata.area == "99") userdata.area = "1";
        if (userdata.ba == "1001" || userdata.ba == "1101") userdata.ba = "1102";
    }
    let userProfile = localStorage.getItem(Key.UserProfile)
    if (userProfile != null) {
        userProfile = JSON.parse(userProfile)
    }
    const currentTime = localStorage.getItem(Key.CurrentTime)
    return (dispatch) => {
        dispatch(validateAuthLoading());
        if (token) {
            dispatch(validateAuthSuccess(token, cb, userdata, userProfile, currentTime));
        } else {
            dispatch(validateAuthFailed(cb));
        }
    };
};

//REGISTER 
/*
export const registerLoading = () => {
    return {
        type: authAction.REGISTER_LOADING,
    };
};

export const registerSuccess = (data, cb) => {
    if (cb) {
        cb(data);
    }
    return {
        type: authAction.REGISTER_SUCCESS,
        data,
    };
};

export const registerFailed = (error) => {
    // notification["error"]({
    //     message: "สมัครสมาชิกไม่สำเร็จ",
    //     description: error,
    // });
    return {
        type: authAction.REGISTER_FAILED,
        error,
    };
};

export const register = (data, cb) => {
    return (dispatch) => {
        dispatch(registerLoading());
        axios
            .post(`${Api.AUTH_REGISTER}`, data)
            .then((response) => {
                dispatch(registerSuccess(response, cb));
            })
            .catch((error) => {
                dispatch(registerFailed(error?.response));
            });
    };
};
*/

//LOGOUT
export const Logout = (cb) => {
    localStorage.removeItem(Key.TOKEN);
    localStorage.removeItem(Key.UserId);
    localStorage.removeItem(Key.UserData);
    localStorage.removeItem(Key.UserProfile);
    // notification["success"]({
    //     message: "ออกจากระบบสำเร็จ",
    // });
    if (cb) {
        cb()
    }
    return {
        type: authAction.LOGOUT_AUTH,
    };
};
