import React, { useEffect, useState, useRef } from "react";
import { Route, Routes, HashRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider, useDispatch } from "react-redux";
import { validateAuth } from "./store/auth/auth.action";
import { HelmetProvider } from "react-helmet-async";

import Loadable from "react-loadable";
//import loadable from '@loadable/component'
import configureStore from "./store";
import StatisticsLayout from "./components/StatisticsLayout";
const { store, persistor } = configureStore();

const Login = Loadable({
  loader: () => import("./pages/login"),
  loading: () => null,
});

const Campus = Loadable({
  loader: () => import("./pages/home"),
  loading: () => null,
});

const Map = Loadable({
  loader: () => import("./pages/map"),
  loading: () => null,
});

const NavbarMenu = Loadable({
  loader: () => import("./components/navbarMenu"),
  loading: () => null,
});

// ----- adding routes for parking status page and all pages under statistic menu -----
const DynamicDashboardPage = Loadable({
  loader: () => import("./pages/statistics/DynamicDashboardPage/index"),
  loading: () => null,
});

const ParkingEventsPage = Loadable({
  loader: () => import("./pages/statistics/ParkingEventsPage/index"),
  loading: () => null,
});

const ParkingFeePage = Loadable({
  loader: () => import("./pages/statistics/ParkingFeePage/index"),
  loading: () => null,
});

const ParkingLotsPage = Loadable({
  loader: () => import("./pages/statistics/ParkingLotsPage/index"),
  loading: () => null,
});

// const ParkingStatusPage = Loadable({
//   loader: () => import("./pages/buildings/ParkingStatusPage/index"),
//   loading: () => null,
// });

const PublicTransport = Loadable({
  loader: () => import("./pages/publicTransport"),
  loading: () => null,
});

const PublicDemo = Loadable({
  loader: () => import("./pages/publicTransportNewDemo"),
  loading: () => null,
});

const Booking = Loadable({
  loader: () => import("./pages/bookingPages/booking"),
  loading: () => null,
});

const HistoryPage = Loadable({
  loader: () => import("./pages/HistoryPages/index"),
  loading: () => null,
});

const BuildingPage = Loadable({
  loader: () => import("./pages/BuildingPages/index"),
  loading: () => null,
});

const GatePage = Loadable({
  loader: () => import("./pages/BuildingPages/gate"),
  loading: () => null,
});

const ParkingStatusPage = Loadable({
  loader: () => import("./pages/BuildingPages/parkingStatus"),
  loading: () => null,
});

const ExpensesPage = Loadable({
  loader: () => import("./pages/ExpensesPages/index"),
  loading: () => null,
});

const SettingRolePage = Loadable({
  loader: () => import("./pages/SettingsPages/rolePage"),
  loading: () => null,
});

const SettingRoleOperationPage = Loadable({
  loader: () => import("./pages/SettingsPages/roleOperationPage"),
  loading: () => null,
});

const SettingUserPage = Loadable({
  loader: () => import("./pages/SettingsPages/userPage"),
  loading: () => null,
});

const SettingUserOperationPage = Loadable({
  loader: () => import("./pages/SettingsPages/userOperationPage"),
  loading: () => null,
});

const SettingBuildingPage = Loadable({
  loader: () => import("./pages/SettingsPages/buildingPage"),
  loading: () => null,
});

const SettingBuildingOperationPage = Loadable({
  loader: () => import("./pages/SettingsPages/buildingOperationPage"),
  loading: () => null,
});

const SettingFloorOperationPage = Loadable({
  loader: () => import("./pages/SettingsPages/floorOperationPage"),
  loading: () => null,
});

const SettingGateOperationPage = Loadable({
  loader: () => import("./pages/SettingsPages/gateOperationPage"),
  loading: () => null,
});

const SettingDevicePage = Loadable({
  loader: () => import("./pages/SettingsPages/devicePage"),
  loading: () => null,
});

const SettingNotificationPage = Loadable({
  loader: () => import("./pages/SettingsPages/notificationPage"),
  loading: () => null,
});

const SettingMonitorPage = Loadable({
  loader: () => import("./pages/SettingsPages/monitorPage"),
  loading: () => null,
});

const SettingHistoryPage = Loadable({
  loader: () => import("./pages/SettingsPages/historyPage"),
  loading: () => null,
});

const MobiTransport = Loadable({
  loader: () => import("./pages/MobiTransportPage/index"),
  loading: () => null,
});

const MobiParking = Loadable({
  loader: () => import("./pages/MobiParkingPage/index"),
  loading: () => null,
});

const MobiChart = Loadable({
  loader: () => import("./pages/MobiChartPage/index"),
  loading: () => null,
});

const MobiPayment = Loadable({
  loader: () => import("./pages/MobiPaymentPage/index"),
  loading: () => null,
});
const MobiBiling = Loadable({
  loader: () => import("./pages/MobiPaymentPage/billing"),
  loading: () => null,
});
const MobiReceipt = Loadable({
  loader: () => import("./pages/MobiPaymentPage/receipt"),
  loading: () => null,
});

const MobiReport = Loadable({
  loader: () => import("./pages/MobiReportPage/index"),
  loading: () => null,
});

const AccesssMonitor = Loadable({
  loader: () => import("./pages/AccessMonitor/index"),
  loading: () => null,
});

const AOIBlueprint = Loadable({
  loader: () => import("./pages/AOIBlueprint/index"),
  loading: () => null,
});
// const KioskCam = Loadable({
//   loader: () => import("./pages/Kiosk/index"),
//   loading: () => null,
// });

// ----- adding routes for parking status page and all pages under statistic menu -----

const App = () => {
  const [Auth, setAuth] = useState(null);
  const [token, setToken] = useState("");
  const [toggleLoadToken, set_toggleLoadToken] = useState(false);
  const dispatch = useDispatch();

  const sessionCalc = () => {
    console.log("aaa token", token);
    let result = true;
    if (token == null) {
      console.log("token null", token);
      return true;
    }
    let arrToken = token.split("_ts");
    if (arrToken.length == 1) {
      console.log("arrToken length 1", arrToken);
      result = true;
    } else {
      let expireTime = new Date(parseInt(arrToken[1] * 1000));
      console.log("expireTime", expireTime);
      console.log("new Date().getTime()", new Date());
      let difTS = new Date().getTime() - expireTime.getTime();
      //console.log("Login Times : ", difTS / (1000 * 60), "mins")
      // if (difTS / (1000 * 60) > 60 * 24) {
      //   console.log("arrToken expire", arrToken)
      //   result = true;
      // } else {
      //   result = false;
      // }
      //console.log("difTS",difTS/ (1000 * 60))
      if (difTS > 0) {
        console.log("arrToken expire", arrToken);
        result = true;
      } else {
        result = false;
      }
    }
    return result;
  };

  const curTime = useRef(new Date());

  const [idleTrig, idleTrigger] = useState({});
  const [selectedBA, setSelectBA] = useState({
    bacode: "1102",
    pwacode: "5542017",
    name: "กปภ.สาขาอ้อมน้อย",
    lat: "13.750294",
    lng: "100.297432",
    zone: "1",
    region: "4",
  });
  const [selectedProfile, setSelectProfile] = useState({
    role_id: "6c5894de-e22e-43c4-b3fa-c30c1f1ac1d0",
    name: "default",
    description: "default role",
    type: "user",
    created_at: "2023-06-18 15:35:33",
    created_by: "Tracy Patton",
    data_permission: [
      {
        building_id: "0ccabdc1-1b2f-44f6-a560-1dccfd84a2a5",
        name: "C",
        description: "",
        enable: true,
        floor: [
          {
            floor_id: "3c9c4b18-8f2f-42d1-a231-0765770e05ef",
            name: "C1",
            description: "test",
            enable: false,
            device: [
              {
                device_id: "9078974334",
                name: "Veronica Boyd",
                description: null,
                enable: false,
              },
            ],
          },
          {
            floor_id: "16615b78-de94-4a1d-bc0d-c68b17b34ce8",
            name: "C2",
            description: null,
            enable: false,
            device: [
              {
                device_id: "9811480370",
                name: "AACDE",
                description: null,
                enable: false,
              },
              {
                device_id: "f6c00979-5da9-4a73-9fd3-5f42db3236fa",
                name: "Andrew Miller",
                description: null,
                enable: false,
              },
            ],
          },
          {
            floor_id: "14a3e103-36b0-4435-afe3-366ea02e2126",
            name: "C3",
            description: null,
            enable: false,
            device: [
              {
                device_id: "4647509927",
                name: "CCVAA",
                description: null,
                enable: false,
              },
              {
                device_id: "4911852842",
                name: "BBADC",
                description: null,
                enable: false,
              },
            ],
          },
          {
            floor_id: "b50141f0-ca91-476d-89c9-042a692ac91f",
            name: "C4",
            description: null,
            enable: false,
            device: [
              {
                device_id: "2553684303",
                name: "RRAD",
                description: null,
                enable: false,
              },
            ],
          },
          {
            floor_id: "20e02eb2-1785-47ca-b84f-4edd424ebac7",
            name: "C5",
            description: null,
            enable: false,
            device: [],
          },
        ],
      },
    ],
    software_permission: [
      {
        name: "Campus",
        description: "หน้าแสดงภาพรวมของข้อมูล",
        enable: true,
        insert_data: true,
        delete_data: true,
      },
      {
        name: "Public Transport",
        description: "หน้าแสดงข้อมูลการขนส่ง",
        enable: true,
        insert_data: true,
        delete_data: true,
      },
      {
        name: "Booking",
        description: "หน้าแสดงข้อมูลการจอง",
        enable: true,
        insert_data: true,
        delete_data: true,
      },
      {
        name: "History",
        description: "หน้าแสดงข้อมูลประวัติการใช้งาน",
        enable: true,
        insert_data: true,
        delete_data: true,
      },
      {
        name: "Building",
        description: "หน้าแสดงข้อมูลอาคาร",
        enable: true,
        insert_data: true,
        delete_data: true,
      },
      {
        name: "Expenses",
        description: "หน้าแสดงข้อมูลค่าใช้จ่าย",
        enable: true,
        insert_data: true,
        delete_data: true,
      },
      {
        name: "Statistics",
        description: "หน้าแสดงข้อมูลสถิติ",
        enable: true,
        insert_data: true,
        delete_data: true,
      },
      {
        name: "Settings",
        description: "หน้าแสดงข้อมูลการตั้งค่า",
        enable: true,
        insert_data: true,
        delete_data: true,
      },
    ],
    parking_permission: [
      {
        building_id: "0ccabdc1-1b2f-44f6-a560-1dccfd84a2a5",
        name: "C",
        description: "",
        free_hour: 0,
        credit_per_hour: 0,
      },
    ],
  });
  const [routePage, set_routePage] = useState("/");

  useEffect(() => {
    let sessionExpired = sessionCalc();
    console.log("sessionExpired", sessionExpired);
    if (!sessionExpired) {
      setAuth(true);
    } else {
      setAuth(false);
      //LogoutAction()
      //console.log(window.location)
    }
  }, [token]);

  useEffect(() => {
    let a = sessionCalc();
    //console.log("a", a)
  });
  useEffect(() => {
    dispatch(
      validateAuth((token, udata, uprofile) => {
        //console.log("data", data)
        setToken(token);
        if (token) {
          if (uprofile != null || uprofile != undefined) {
            //console.log("uprofile", JSON.stringify(uprofile))
            //setSelectProfile(uprofile);
          }
          //setAuth(true);
        }
      })
    );
  }, []);
  const LandingPage = (prop) => {
    let { selectedProfile } = prop;
    console.log("selectedProfile", selectedProfile)
    let element = <Campus selectedProfile={selectedProfile} />;
    if (selectedProfile.software_permission[0].enable) {
      element = <Campus selectedProfile={selectedProfile} />;
    } else if (selectedProfile.software_permission[1].enable) {
      element = <PublicTransport selectedProfile={selectedProfile} />;
    } else if (selectedProfile.software_permission[2].enable) {
      element = <Booking selectedProfile={selectedProfile} />;
    } else if (selectedProfile.software_permission[3].enable) {
      element = <HistoryPage selectedProfile={selectedProfile} />;
    } else if (selectedProfile.software_permission[4].enable) {
      element = <ExpensesPage selectedProfile={selectedProfile} />;
    } else if (selectedProfile.software_permission[5].enable) {
      element = <BuildingPage selectedProfile={selectedProfile} />;
    } else if (selectedProfile.software_permission[6].enable) {
      element = <DynamicDashboardPage selectedProfile={selectedProfile} />;
    } else if (selectedProfile.software_permission[7].enable) {
      element = <SettingRolePage selectedProfile={selectedProfile} />;
    }
    return element;
  };

  const Allpage_Role = (selectedProfile) => {
    if (selectedProfile != undefined) {
      return (
        <>
          <Route
            path="/"
            element={<LandingPage selectedProfile={selectedProfile} />}
            exact
          />

          <Route
            path="/campus"
            element={
              <Campus
              // selectedBA={selectedBA}
              // routePage=""
              // routePath="/campus"
              // set_routePage={set_routePage}
              // idleTrigger={idleTrigger}
              // selectedProfile={selectedProfile}
              />
            }
            exact
          />
          <Route
            path="/map"
            element={
              <Map
              // selectedBA={selectedBA}
              // routePage=""
              // routePath="/map"
              // set_routePage={set_routePage}
              // idleTrigger={idleTrigger}
              // selectedProfile={selectedProfile}
              />
            }
            exact
          />
          <Route path="/publicTransport" element={<PublicTransport />} />
          <Route path="/publicDemo" element={<PublicDemo />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/history" element={<HistoryPage />} />
          <Route path="/expenses" element={<ExpensesPage />} />

          <Route path="buildings" element={<BuildingPage />}>
            <Route path=":buildingId" element={<BuildingPage />}>
              <Route index element={<BuildingPage />} />
            </Route>
          </Route>

          <Route path="buildingsGate" element={<GatePage />}>
            <Route path=":buildingId" element={<GatePage />}>
              <Route index element={<GatePage />} />
            </Route>
          </Route>

          <Route path="buildingsParkingStatus" element={<ParkingStatusPage />}>
            <Route path=":buildingId" element={<ParkingStatusPage />}>
              <Route index element={<ParkingStatusPage />} />
            </Route>
          </Route>

          <Route path="floors" element={<AOIBlueprint />}>
            <Route path=":buildingId" element={<AOIBlueprint />}>
              <Route path=":floorId" element={<AOIBlueprint />}>
                <Route index element={<AOIBlueprint />} />
              </Route>
            </Route>
          </Route>

          <Route path="statistics" element={<StatisticsLayout />}>
            <Route path="dynamicDashboard" element={<DynamicDashboardPage />} />
            <Route path="parkingEvents" element={<ParkingEventsPage />} />
            <Route path="parkingFee" element={<ParkingFeePage />} />
            <Route path="parkingLots" element={<ParkingLotsPage />} />
          </Route>

          <Route path="/setting/role" element={<SettingRolePage exact />} />
          <Route path="/setting/role-operation" element={<SettingRoleOperationPage exact />} />
          <Route path="/setting/user" element={<SettingUserPage exact />} />
          <Route path="/setting/user-operation" element={<SettingUserOperationPage exact />} />
          <Route
            path="/setting/building"
            element={<SettingBuildingPage exact />}
          />
          <Route
            path="/setting/building-operation"
            element={<SettingBuildingOperationPage exact />}
          />
          <Route
            path="/setting/floor-operation"
            element={<SettingFloorOperationPage exact />}
          />
          <Route
            path="/setting/gate-operation"
            element={<SettingGateOperationPage exact />}
          />
          <Route path="/setting/device" element={<SettingDevicePage exact />} />
          <Route
            path="/setting/notification"
            element={<SettingNotificationPage exact />}
          />
          <Route
            path="/setting/monitor"
            element={<SettingMonitorPage exact />}
          />
          <Route
            path="/setting/history"
            element={<SettingHistoryPage exact />}
          />


          <Route path="/accessMonitor" element={<AccesssMonitor />} exact />
          <Route path="/aoiblueprint" element={<AOIBlueprint />} exact />
        </>
      );
    }
  };

  return (
    <HelmetProvider>
      <HashRouter>
        {Auth ? (
          <>
            <NavbarMenu
              setSelectBA={setSelectBA}
              sessionCalc={sessionCalc}
              curTime={curTime}
              idleTrig={idleTrig}
              selectedProfile={selectedProfile}
            />
            <Routes>{Allpage_Role(selectedProfile)}</Routes>
          </>
        ) : (
          <Routes>
            <Route path="/" element={<Login setToken={setToken} />} exact />
            <Route path="/login" element={<Login />} exact />
            <Route path="/campus" element={<Login />} exact />
            <Route path="/map" element={<Map />} exact />
            <Route path="/publicTransport" element={<Login />} exact />
            <Route path="/publicDemo" element={<PublicDemo />} exact />
            <Route path="/booking" element={<Login />} exact />
            <Route path="/history" element={<Login />} exact />
            <Route path="/expenses" element={<Login />} exact />
            <Route path="buildings" element={<Login />}>
              <Route path=":buildingId" element={<Login />}>
                <Route index element={<Login />} />
              </Route>
            </Route>
            <Route path="/statistics" element={<Login />} exact />
            <Route path="/dynamicDashboard" element={<Login />} exact />
            <Route path="/parkingEvents" element={<Login />} exact />
            <Route path="/parkingFee" element={<Login />} exact />
            <Route path="/parkingLots" element={<Login />} exact />
            <Route path="/setting/role" element={<Login />} exact />
            <Route path="/setting/role-operation" element={<Login />} exact />
            <Route path="/setting/user" element={<Login />} exact />
            <Route path="/setting/building" element={<Login />} exact />
            <Route
              path="/setting/building-operation"
              element={<Login />}
              exact
            />
            <Route path="/setting/floor-operation" element={<Login />} exact />
            <Route path="/setting/device" element={<Login />} exact />
            <Route path="/setting/notification" element={<Login />} exact />
            <Route path="/setting/monitor" element={<Login />} exact />
            <Route path="/setting/history" element={<Login />} exact />

            <Route path="/accessMonitor" element={<Login />} exact />
            <Route path="/aoiblueprint" element={<Login />} exact />

          </Routes>
        )}
      </HashRouter>
      <HashRouter>
        {Auth ? (
          <Routes>
            <Route path="/mobility/parking" element={<LandingPage selectedProfile={selectedProfile} />} exact />
            <Route path="/mobility/transport" element={<LandingPage selectedProfile={selectedProfile} />} exact />
            <Route path="/mobility/payment" element={<LandingPage selectedProfile={selectedProfile} />} exact />
            <Route
              path="/mobility/payment/billing"
              element={<LandingPage selectedProfile={selectedProfile} />}
              exact
            />
            <Route
              path="/mobility/payment/receipt"
              element={<LandingPage selectedProfile={selectedProfile} />}
              exact
            />
            {/* <Route path="/mobility/findmycar" element={<MobiFindMyCar />} exact /> */}
            <Route path="/mobility/report" element={<LandingPage selectedProfile={selectedProfile} />} exact />
            {/* <Route path="/kioskgate" element={<KioskCam />} >
              <Route path=":gateType" element={<KioskCam />}>
                <Route path=":gateId" element={<KioskCam />}>
                  <Route index element={<KioskCam />} />
                </Route>
              </Route>
            </Route> */}
          </Routes>
        ) : (
          <Routes>
            <Route path="/mobility/parking" element={<MobiParking />} exact />
            <Route path="/mobility/chart" element={<MobiChart />} exact />
            <Route
              path="/mobility/transport"
              element={<MobiTransport />}
              exact
            />
            <Route path="/mobility/payment" element={<MobiPayment />} exact />
            <Route
              path="/mobility/payment/billing"
              element={<MobiBiling />}
              exact
            />
            <Route
              path="/mobility/payment/receipt"
              element={<MobiReceipt />}
              exact
            />
            {/* <Route path="/mobility/findmycar" element={<MobiFindMyCar />} exact /> */}
            <Route path="/mobility/report" element={<MobiReport />} exact />
            {/* <Route path="/kioskgate" element={<KioskCam />} >
              <Route path=":gateType" element={<KioskCam />}>
                <Route path=":gateId" element={<KioskCam />}>
                  <Route index element={<KioskCam />} />
                </Route>
              </Route>
            </Route> */}
          </Routes>
        )}
      </HashRouter>
    </HelmetProvider>
  );
};

const MyRoutes = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
};

export default MyRoutes;
