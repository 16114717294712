import axios from "axios";
import { getAccessToken } from "../util/userUtil";

export const craApi = axios.create({
  baseURL: process.env.REACT_APP_APIAUTHURL,
});

craApi.interceptors.request.use(
  (config) => {
    const token = getAccessToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete craApi.defaults.headers.common.Authorization;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const craPublicApi = axios.create({
  baseURL: process.env.REACT_APP_APIPUBLIC,
});

export default craApi;
